body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  color: #fff;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the alpha value (0.5 in this case) to change the opacity */


}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.navbar-brand {
  font-weight: bold;

}

.navbar-links {
  display: flex;
  margin: auto;
  font-family: Calibri;
  font-weight: 600;
}

.navbar-links a{
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.pic{
  text-align: center;
  align-items: center;
   background-color: purple;
   height: 95vh;
}

.pic img{
  border-radius: 50%;
  margin: 1rem;
  transition: 1s ease; 
 ;/* Adjust the scale factor as needed */
}

.pic img:hover{
  -webkit-transform: scale(1.2);
-ms-transform: scale(1.2);
transform: scale(1.2);
transition: 1s ease;
}



.pic p{
  font-family: segoe print;
  color: white;
  font-weight: 900;
}

.pic h3{
  color: aliceblue;
font-size: 0.9rem;
  font-family: Cascadia Code Light;
}



.About h3{
  color: purple;
  font-family: segoe ui;
  text-decoration: overline;
  text-align: center;
}


.Area{
  margin:3rem ;
  border-left: 7px solid purple;
  border-radius: 1.5rem ;
}

.Area p{
  margin: 1rem;
  font-family: calibri;
  font-weight: 100;
}


.projects h3{
  color: purple;
  font-family: segoe ui;
  text-decoration: overline;
}

.projects{
  margin: 1rem;
}

.containerr img{
  transition: 1s ease; 

}

.containerr img:hover{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

button a{
  text-decoration: none;
  color: white;
}

button{
  background: linear-gradient( purple, black);
  border: none;
  padding: 0.8rem 3rem;
}

.projects p{
  font-family: calibri;
  font-weight: 100;
}



